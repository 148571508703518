import styled from 'styled-components'

export const FormWrapper = styled.div<{ orientation?: string}>`
  display: grid;
  gap: 20px;
  margin-bottom: 24px;

  ${({orientation}) => orientation == 'horizontal' ? 'grid-template-columns: repeat(auto-fill, max(350px));' : ''}
  max-width: ${({orientation}) => orientation == 'horizontal' ? '100%' : '500px'};
  align-items: start;
  justify-content: space-between;
  /*
  &>div {
    width: fit-content;
  }
  */
  @media (max-width: 1200px) {
    gap: 10px;
  }
`
