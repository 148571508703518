import React, { memo, useCallback, useEffect, useState } from 'react'
import { Alert, Modal, Spin } from 'antd'
import { DefaultJsonForm } from '../../../../../../components/v2/default-json-form'
import { convertData2JsonForm } from '../../../../../../shared/lib/convertData2JsonForm'
import { isFormDataRequired } from '../../../../../../../lib/utils/collections'
import fetchApi from '../../../../../../../lib/utils/fetch-api'
import fetch from 'isomorphic-fetch'

interface PrintPrepareModalProps {
  id: number | string
  template: null | {
    id: number
    name: string
  }
  isModalPrintOpen: boolean
  onCancel?: (() => void) | undefined
}

export const PrintPrepareModal: React.FC<PrintPrepareModalProps> = memo((props: PrintPrepareModalProps) => {
  const { id, template, isModalPrintOpen, onCancel } = props

  if (!isModalPrintOpen) {
    return null
  }
  if (!template?.id) {
    return <Alert message='Неверный ID шаблона' type='error' showIcon />
  }

  const valueToNumber = (value: string | number) =>
    parseFloat(String(value).replace(',', '.').replace(/[^0-9.]/g, '')) || 0

  const [formData, setFormData] = useState<any>({})
  const [schema, setSchema] = useState<any>()
  const [loading, setLoading] = useState<any>(true)

  const downloadUrl = useCallback(async (url: string) => {
    const data = {
      ...formData,
      total_cost: valueToNumber(formData?.total_cost),
      prepayment_sum: valueToNumber(formData?.prepayment_sum),
      payment_sum: valueToNumber(formData?.payment_sum),
      remains_sum: valueToNumber(formData?.remains_sum),
    }
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    const contentType = response.headers.get('Content-Type')
    if (contentType && contentType.includes('application/json')) {
      const content = await response.json()
      Modal.error({
        title: 'Ошибка',
        content: (
          <>
            Не удалось получить документ, неизвестная переменная <b>{content.command}</b>
          </>
        ),
      })
    }
    const blob = await response?.blob()
    if (blob) {
      const disposition = response.headers.get('Content-Disposition')
      const filename = (() => {
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const matches = /filename[^;=\n]*=((['"]).*?\\2|[^;\n]*)/.exec(disposition)
          if (matches != null && matches[1]) {
            return decodeURIComponent(matches[1].replace(/['"]/g, ''))
          }
        }
        return 'file'
      })()
      const downloadAnchorNode = document.createElement('a')
      const url = window.URL.createObjectURL(blob)
      downloadAnchorNode.setAttribute('href', url)
      downloadAnchorNode.setAttribute('download', filename)
      document.body.appendChild(downloadAnchorNode)
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
      window.URL.revokeObjectURL(url)
      onCancel?.()
    }
  }, [formData, onCancel])

  const printTemplate = useCallback(() => {
    const url = `/api/order_print/${template.id}/${id}`
    downloadUrl(url)
  }, [template, id, formData])

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchApi(`/api/order_print_prepare/${template.id}/${id}`)
      const schema = convertData2JsonForm(data)

      const actualData = (() => {
        const newSchema = { ...schema.resultObject }
        const total_cost = Number(newSchema?.['total_cost'])
        const prepayment_sum = Number(newSchema?.['prepayment_sum'])
        if (prepayment_sum < total_cost) {
          newSchema['remains_sum'] = (total_cost - prepayment_sum).toFixed(2)
        }
        return newSchema
      })()

      setFormData(actualData)
      setSchema(schema.resultSchema)
      setLoading(false)
    }
    fetchData()
  }, [])

  const handleOk = useCallback(() => {
    const isRequiredFields = isFormDataRequired(formData, schema)
    if (!isRequiredFields) {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Заполните все обязательные поля',
      })
      return
    }
    printTemplate()
  }, [formData, schema])

  const handleCancel = () => {
    onCancel?.()
  }

  const formatCurrency = (value: number) =>
    value
      .toLocaleString('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        currencyDisplay: 'code',
      })
      .replace(/\s*RUB/, '')

  const handleChange = data => {
    setFormData(prev => {

      const total_cost = valueToNumber(prev.total_cost)
      const prepayment_sum = valueToNumber(prev.prepayment_sum)
      let payment_sum = valueToNumber(data.formData?.payment_sum)
      let remains_sum = 0

      if (total_cost !== prepayment_sum) {
        remains_sum = total_cost - prepayment_sum - payment_sum
      }

      if (total_cost < prepayment_sum + payment_sum) {
        remains_sum = 0
      }

      return {
        ...prev,
        payment_sum: (total_cost < prepayment_sum + payment_sum) ? total_cost - prepayment_sum : data.formData?.payment_sum,
        remains_sum: formatCurrency(remains_sum),
      }
    })
  }

  return (
    <Modal
      title={`Подготовка к печати: "${template.name}"`}
      onOk={handleOk}
      onCancel={handleCancel}
      visible={isModalPrintOpen}
      destroyOnClose={true}
      okButtonProps={{ disabled: loading }}
      bodyStyle={{
        maxHeight: '70vh',
        overflow: 'auto',
      }}
    >
      {loading ? (
        <Spin />
      ) : (
        <DefaultJsonForm
          formData={formData}
          schema={Object.entries(schema).length ? schema : {}}
          onChange={handleChange}
          orientation='horizontal'
        />
      )}
    </Modal>
  )
})
