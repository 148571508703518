import React, { memo, useCallback, useEffect, useState } from 'react'
import { HStack } from '../../../../ui/Stack'
import { Button } from 'antd'

interface IButton{
  name: string
  code: string
  isActive: boolean
  disabled?: boolean
}

interface IFilterButtons {
  onChange: (filters: any, isButtonChange?: boolean) => void
  disabled: boolean
}

export const QuickFilterButtons = memo((props: IFilterButtons) => {
  const { disabled, onChange } = props
  const buttonsList = [
    { name: 'Продажи', code: 'sales', isActive: false },
    { name: 'Замеры', code: 'measurements', isActive: false },
    { name: 'Доставка', code: 'delivery', isActive: false, disabled: true },
    { name: 'Монтажи', code: 'montages', isActive: false },
  ]
  const [buttons, setButtons] = useState<IButton[]>(buttonsList)
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled)

  const onClickButton = useCallback((button: IButton) => () => {
    const newState = buttons.map(b => b.code === button.code ? { ...b, isActive: !b.isActive } : b)
    console.log({ newState })
    setButtons(newState)
    onChange(newState.filter(b => b.isActive).map(b => b.code))
  }, [onChange, setButtons, buttons])

  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])

  return (
    <HStack gap='16' justify='start' max>
      {buttons?.map(button => (
        <Button
          disabled={button?.disabled || isDisabled}
          key={button.name}
          type={button.isActive ? 'primary' : 'default'}
          onClick={onClickButton(button)}
        >
          {button.name}
        </Button>
      ))}
      {JSON.stringify({ isDisabled, disabled })}
    </HStack>
  )
})
